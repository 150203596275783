@import '../../styles/globalStyles.scss';

.app-pagination {
  width: 100%;
  position: relative;
  padding: 4px 0;
  .app-pagination-buttons {
    display: flex;
    align-items: center;
    width: 270px;
    font-weight: bold;
    .MuiIconButton-root{
      border-radius: 4px;
      height: 40px;
      .makeStyles-root-102{
        padding-left: 3px;
      }
    }
    .MuiIconButton-root {
      .MuiTouchRipple-root {
        display: none;
      }
      &:hover {
        background-color: $light-border;
        border-radius: 0;
      }
    }
    .MuiFilledInput-input{
      border: 1px solid $ey-phoenix-light-gray-2;
      border-radius: 4px;
      background: $white;
      text-align: center;
      padding: 0px 5px;
      height: 44px;
      &:hover {
        background-color: $light-border;
      }
    }
    .page-count {
      display: inline-block;
      min-width: 50px;
      text-align: center;
    }
  }
  .MuiTablePagination-spacer {
    display: none;
  }
  .MuiTablePagination-toolbar{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 0;
  }
  .MuiTablePagination-caption{
    font-size: $fontSize16;
    padding-right: 5px;
  }
  .MuiTablePagination-select{
    height:30px;
    border-radius: 4px !important;
    align-content: center;
    &:hover {
      background-color: $light-border;
    }
   
  }
  .MuiTablePagination-selectRoot {
    margin-left: 0px;
    margin-right: auto;
    border: 1px solid transparent;
    font-size: medium;
  }
  .MuiFilledInput-underline{
    &:hover {
      background-color: $light-border;
    }
  }
  .app-input {
    margin-left: 10px;
    .MuiFilledInput-underline::before {
      border:0px;
    }
  }
}
