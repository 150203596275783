@import '../../styles/variables.scss';

.emailChange{
    .progressEmailChange{
        justify-content:center !important;
        display : flex !important;
    }
    h3{
        color: $selected_color !important;
        margin: 0 0 10px;
    }
    p{
        margin: 0 0 10px;
    }
}
