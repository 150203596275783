@import "../../styles/variables.scss";
@import "../../styles/globalStyles.scss";

@include search-wrapper();

.custom-engagement-close-project {
  .motif-app-text-field {
    .MuiInputBase-multiline {
      &:hover {
        background-color: $disabled_text_color !important;
        cursor: pointer;
      }

      &:focus {
        background-color: $disabled_text_color !important;
        cursor: pointer;
      }
    }

    .MuiFilledInput-input {
      &:hover {
        background-color: $disabled_text_color !important;
        cursor: pointer;
      }

      &:focus {
        background-color: $disabled_text_color !important;
        cursor: pointer;
      }
    }
  }

  .modal-content {
    max-width: 875px !important;
    padding-right: 10px;
    padding-bottom: 30px;
  }

  .MuiDialogContent-root {
    padding: 24px 0px 0px 0px !important;

    /* Custom scrollbar styles */
    &::-webkit-scrollbar {
      width: 8px;
      /* Width of the scrollbar */
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      /* Background of the scrollbar track */
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      /* Color of the scrollbar thumb */
      border-radius: 4px;
      /* Rounded corners for the scrollbar thumb */
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
      /* Color of the scrollbar thumb on hover */
    }
  }

  .MuiDialogTitle-root {
    color: $secondary_button_grey !important;
    font-family: $eyInterstateRegularNew !important;
    font-weight: 400 !important;
    font-size: $fontSize20 !important;
    padding: 0px 0px 12px 0px !important;
  }

  .rbt-input-hint {
    display: none;
  }

  .MuiDialog-paperFullWidth {
    max-width: 862px !important;
    padding: 24px 32px 24px 32px;
  }

  .motif-close-project {
    .MuiList-padding {
      padding-left: 8px !important;
    }

    .rbt-close {
      font-size: $fontSize20 !important;
    }

    .divider {
      border-bottom: 1px solid $light-border;
      margin-top: 24px;
    }

    .Close-circleCheck {
      width: 20px;

      .app-button svg {
        width: 40px !important;
      }
    }

    .errorEngagementSearchUser {
      margin-top: -6px !important;
    }

    .search-wrapper .dropdown-menu {
      scrollbar-width: none;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .search-wrapper .dropdown-menu .dropdown-item {
      background: $white;
      white-space: nowrap;
      text-overflow: ellipsis;
      scrollbar-width: none;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .async-dropdown {
      background: $white;
      padding: 5px 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      scrollbar-width: none;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    search-wrapper .rbt-input-main:focus,
    .search-wrapper .rbt-input:focus,
    .search-wrapper .form-control:focus {
      outline: none;
      box-shadow: none;
      border-color: initial;
    }

    .asterisk {
      color: $motif-error-red;
    }

    .input-label {
      font-family: $eyInterstateRegularNew !important;
      font-weight: 400;
      font-size: $fontSize14;
      // color: $ey-phoenix-light-gray;
    }

    .search-wrapper {
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .focus {
        border: 1px solid $ey-phoenix-light-gray !important;
      }

      .rbt {
        width: 100%;
      }
    }

    .MuiFilledInput-inputMultiline {
      font-family: $eyInterstateRegularNew !important;
      font-weight: 400 !important;
      font-size: $fontSize14 !important;
      color: $ey-phoenix-light-gray !important;
    }

    .error-alert-custom {
      height: 60px;

      .MuiAlert-icon {
        width: 20px;
        margin-right: 10px;
        padding-left: 0px;
      }

      .MuiAlert-colorError {
        padding: 10px;
        height: 55px;
      }
    }

    .info-alert-custom {
      .MuiAlert-icon {
        color: $ey-phoenix-light-gray !important;
        width: 20px;
        margin-bottom: 12px;
        margin-right: 5px;
        padding-left: 0px;
        margin-bottom: 0px !important;
      }

      margin-top: 25px;
      margin-bottom: 10px;
      background-color: $ey-dull-background !important;
      border-radius: 4px;
      border: solid 1px $ey-phoenix-light-gray-2;
      color: $breadcrumb-color-active !important;
      font-family: $eyInterstateRegularNew !important;
      font-weight: 400 !important;
      font-size: $fontSize14 !important;
    }

    .close-project-feedback {
      font-family: EYInterstate-light;
      display: flex;
      flex-direction: column;
      padding-top: 20px;

      .row {
        display: flex;
        flex-grow: 1;
        padding-bottom: 10px;
        gap: 20px;

        .question {
          font-family: $eyInterstateRegularNew;
          width: calc(50% - 10px);
          font-weight: 400;
          font-size: $fontSize14;
          color: $breadcrumb-color-active;
          // display: flex;

          .app-tooltip {
            display: inline-block;
            cursor: pointer;
            width: auto;
            margin-left: 5px;
          }
        }

        .feedback-input {
          width: calc(50% - 10px);
          position: relative;
        }
      }

      .rbt-input-main {
        padding: 0px 0px 4px 10px !important;
      }

      .rbt-input {
        width: 100%;
        height: 40px;
        padding: 0px 0px 4px 10px !important;
        font-weight: 300 !important;
        font-family: $eyInterstateLightNew !important;
        font-size: $fontSize14 !important;
        color: $ey-phoenix-light-gray !important;
        margin-bottom: 0;

        &:hover {
          background-color: $disabled_text_color;
          cursor: pointer;
        }
      }

      .no-engagement-info-msg {
        margin-left: 5px;
        display: inline-block;

        .app-icon {
          font-size: $fontSize14;
        }
      }

      .cic-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .col-6 {
          font-family: $eyInterstateRegularNew;
          width: 49%;
          font-size: $fontSize14;
          color: $ey-phoenix-light-gray;
          font-weight: 400;
          line-height: 1.4;
        }

        .select-wrapper {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }

        .input-label {
          display: none;
        }

        .motif-select {
          font-family: $eyInterstateRegularNew;
          width: 100%;
          font-weight: 400;
          font-size: $fontSize14;
          color: $breadcrumb-color-active;
          height: 39px;
        }
      }
    }

    .file-upload-wrapper {
      background: $ey-dull-background;
      padding: 20px;
      margin: 10px 0px 10px 0px;
      gap: 6px;

      .input-label {
        font-family: $eyInterstateRegularNew !important;
        font-weight: 400 !important;
        margin-bottom: 6px;
        color: $ey-phoenix-light-gray !important;
      }

      .drag-drop-wrapper[style*="pointer-events: none"] {
        opacity: 0.5;
        cursor: not-allowed;
      }

      .drag-drop-wrapper {
        background: $white;
        border: 1px dashed $ey-phoenix-light-gray-2;
        height: 126px;
        padding: 16px 24px 16px 24px;
        border-radius: 4px;
        gap: 4px;

        .dropzone {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;

          .upload-icon-size {
            max-width: 5%;
            max-height: 5%;
          }

          .upload-text {
            font-family: $eyInterstateBold;
            font-weight: 400;
            font-size: $fontSize14;
            color: $breadcrumb-color-active;
            margin-top: 12px;
          }

          .upload-options {
            font-family: $eyInterstateLightNew;
            font-weight: 300;
            font-size: $fontSize12;
            color: $ey-phoenix-light-gray;
          }
        }

        &:hover {
          background-color: $disabled_text_color;
          cursor: pointer;
        }
      }

      .drag-drop-wrapper-alert {
        .MuiAlert-icon {
          color: $ey-error-icon-red;
        }

        background: $light-red-0001;
        border: 1px dashed $ey-alert-red;
        border-radius: 4px;
        width: 750px;
        height: 126px;
        padding: 16px 24px 16px 24px;
        gap: 4px;
        color: $ey-error-icon-red;

        .dropzone {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;

          .upload-icon-size {
            max-width: 5%;
            max-height: 5%;
          }

          .upload-text {
            font-family: $eyInterstateBold;
            font-weight: 400;
            font-size: $fontSize14;
            color: $ey-alert-red;
            margin-top: 12px;
          }

          .upload-options {
            font-family: $eyInterstateLightNew;
            font-weight: 300;
            font-size: $fontSize12;
            color: $ey-alert-red;
          }
        }
      }

      .file-list {
        margin-top: 10px;
        align-items: flex-start;
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        width: 750px;

        .file {
          background: $white;
          border: 1px solid $ey-border-grey;
          border-radius: 4px;
          color: $unpublished_bg_color;
          height: 40px;
          justify-content: space-between;
          display: flex;
          width: calc(50% - 5px);

          .filename {
            padding-left: 16px;
            height: 40px;
            display: block;
            align-items: center;
            justify-content: flex-start;
            font-family: $eyInterstateRegularNew;
            font-weight: 400;
            font-size: $fontSize14;
            color: $secondary_button_grey;
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            line-height: 40px;
          }

          .file-icon-cross {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex-grow: 1;

            .focus {
              cursor: pointer;
            }
          }

          .focus {
            cursor: pointer;
          }
        }
      }
    }
  }
}